export type PrescriptionFlowStepIdKey = keyof typeof PRESCRIPTION_FLOW_STEP_ID;
export type PrescriptionFlowStepId = typeof PRESCRIPTION_FLOW_STEP_ID[PrescriptionFlowStepIdKey];

export const PRESCRIPTION_FLOW_STEP_ID = {
    CONFIRMATION: 'confirmation',
    CURRENT_PRESCRIPTION: 'current-prescription',
    HEALTH_ALLERGIES: 'health-allergies',
    PAYMENT: 'payment',
    PRESCRIBER_DETAILS: 'prescriber-details',
    PRESCRIPTION_DETAILS: 'prescription-details',
    SHIPPING_ADDRESS: 'shipping-address'
} as const;
