import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextSetValue from 'ui-kit/text/textSetValue';

import { setPharmacyListVisibility } from 'state/pharmacy/pharmacy.reducer';
import { pharmacyLookUpRoutine } from 'state/pharmacy/pharmacy.routines';
import { pharmacyLookupSelector, pharmacySelector } from 'state/pharmacy/pharmacy.selector';

import { PharmacyList } from './pharmacy-list.component';

const PharmacyLookup = ({ field, form, formError, onChange, ...rest }: any) => {
    const [isFocused, setIsFocused] = useState(false);
    const [textHasChanged, setTextHasChanged] = useState(false);
    const [selectedPharmacyName, setSelectedPharmacyName] = useState(rest.defaultValue);
    const [activePharmacyIndex, setActivePharmacyIndex] = useState(-1);
    const [searchedText, setSearchedText] = useState<string>(selectedPharmacyName);
    const pharmacyNameRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();
    const pharmacies = useSelector(pharmacyLookupSelector);
    const { showPharmacyList } = useSelector(pharmacySelector);

    const lookupText = (searchName?: string) => {
        rest.defaultValue = undefined;

        if (searchName && searchName.length > 2) {
            dispatch(
                pharmacyLookUpRoutine.trigger({
                    pharmacyName: searchName
                })
            );
        }
    };

    const debounceLookupText = debounce(lookupText, 250);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextHasChanged(true);
        const searchName = event.currentTarget.value;
        form.setFieldValue(field.name, searchName);
        setSearchedText(searchName);
        onChange?.(searchName);

        debounceLookupText(searchName);

        if (!searchName || searchName.length <= 3) {
            dispatch(setPharmacyListVisibility(false));
        }
    };

    const handleClick = (pharmacy: string, listIndex: number) => {
        form.setFieldValue(field.name, pharmacy);
        dispatch(setPharmacyListVisibility(false));
        setActivePharmacyIndex(listIndex);
    };

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setTextHasChanged(true);
        setIsFocused(true);

        const searchName = event.currentTarget.value;
        setSearchedText(searchName);
        if (searchName.length > 2) {
            lookupText(searchName);
        }
    };

    const handleOnBlur = () => {
        setTimeout(() => {
            dispatch(setPharmacyListVisibility(false));
            setIsFocused(false);
        }, 200);

        if (selectedPharmacyName) {
            form.setFieldValue(field.name, selectedPharmacyName);
        }
        rest.onPharmacyBlur(form);
    };

    const onActiveIndexChange = (newIndex: number) => {
        setActivePharmacyIndex(newIndex);
        if (newIndex > -1) {
            const pharmacy = pharmacies[newIndex];
            form.setFieldValue(field.name, pharmacy);
        }
        rest.onPharmacyBlur(form);
    };

    const handleKeyDown = (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
        if (pharmacies.length > 0) {
            let newIndex = -1;
            if (keyEvent.key === 'ArrowUp') {
                dispatch(setPharmacyListVisibility(true));
                newIndex = activePharmacyIndex > 0 ? activePharmacyIndex - 1 : pharmacies.length - 1;
                onActiveIndexChange(newIndex);
            } else if (keyEvent.key === 'ArrowDown') {
                dispatch(setPharmacyListVisibility(true));
                newIndex = activePharmacyIndex < pharmacies.length - 1 ? activePharmacyIndex + 1 : 0;
                onActiveIndexChange(newIndex);
            } else if (keyEvent.key === 'Enter') {
                keyEvent.preventDefault();
                rest.onPharmacyBlur(form);
                const pharmacyIndex = activePharmacyIndex > -1 ? activePharmacyIndex : 0;
                handleClick(pharmacies[pharmacyIndex], activePharmacyIndex);
            } else if (keyEvent.key === 'Tab') {
                dispatch(setPharmacyListVisibility(false));
                setIsFocused(false);
                rest.onPharmacyBlur(form);
            } else {
                onActiveIndexChange(-1);
            }
        }
    };

    const classes = classNames('pharmacy-lookup', { focused: isFocused });

    useEffect(() => {
        if (selectedPharmacyName) {
            handleOnBlur();
        }
        return () => {
            setIsFocused(false);
            setSelectedPharmacyName('');
            setActivePharmacyIndex(-1);
            setTextHasChanged(false);
        };
    }, []);

    useEffect(() => {
        if (formError && selectedPharmacyName) {
            if (pharmacyNameRef.current) {
                pharmacyNameRef.current.focus();
            }
        }
    }, [formError, selectedPharmacyName]);

    return (
        <div className={classes}>
            <TextSetValue
                name={rest.name ? rest.name : 'pharmacyName'}
                label={rest.label}
                type="text"
                onChange={handleChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onKeyDown={handleKeyDown}
                value={selectedPharmacyName ? selectedPharmacyName : ''}
                ariaExpanded={showPharmacyList ? 'true' : 'false'}
                autocomplete="off"
                inputRef={pharmacyNameRef}
                {...rest}
            />
            {textHasChanged && pharmacies?.length !== 0 && showPharmacyList && isFocused ? (
                <PharmacyList
                    searchText={searchedText}
                    pharmacies={pharmacies}
                    handleClick={handleClick}
                    activeIndex={activePharmacyIndex}
                />
            ) : null}
        </div>
    );
};

export default PharmacyLookup;
