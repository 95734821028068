import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const pharmacySelector = (state: RootState) => state.pharmacyReducer;

export const pharmacyPastPharmacyFlowSelector = createSelector(pharmacySelector, (pharmacy) =>
    Boolean(pharmacy.isPastPharmacyFlow)
);

export const pharmacyLookupSelector = createSelector(
    pharmacySelector,
    (pharmacies) => pharmacies.pharmacyLookupResult ?? []
);

export const pharmacySearchFlowSelector = createSelector(pharmacySelector, (pharmacy) =>
    Boolean(pharmacy.isPharmacySearchFlow)
);

export const isSearchingPharmacySelector = createSelector(pharmacySelector, (pharmacy) =>
    Boolean(pharmacy.isSearchingPharmacies)
);

export const isSearchingPharmaciesSelector = createSelector(pharmacySelector, (pharmacy) =>
    Boolean(pharmacy.isSearchingPharmacies)
);

export const lastPharmacySearchFormValuesSelector = createSelector(
    pharmacySelector,
    (pharmacy) => pharmacy.lastPharmacySearchFormValues
);

export const pharmacySearchGroupResults = createSelector(
    pharmacySelector,
    (pharmacy) => pharmacy.pharmacyGroupByResult
);
