import * as yup from 'yup';
import { validatePhoneNumber } from 'schema/validation/phone-number';

export const PHARMACY_SCHEMA = (countryCode: 'US' | 'CA') =>
    yup.object().shape({
        pharmacyName: yup.string().required(),
        pharmacyPhone: yup
            .string()
            .test(
                'Phone Number validation',
                (value: string | undefined, context: yup.TestContext<Record<string, any>>) =>
                    validatePhoneNumber(value, context, countryCode)
            )
    });

export const PHARMACY_SEARCH_SCHEMA = () =>
    yup.object().shape({
        name: yup.string().min(3, 'invalid length').required(),
        city: yup.string().min(3, 'invalid length').required(),
        state: yup.string().required()
    });
