import { useDispatch, useSelector } from 'react-redux';

import {
    FlowTypePayload,
    clearAddTransferObject,
    setStep,
    setFlowType,
    setTotalStep,
    setShowPaymentStep,
    setShowAddressStep
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { addTransferPrescriptionStepSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { accountCreditCardsSelector, accountProfileAddressesSelector } from 'state/account/account.selectors';
import storageHelper from 'util/storageHelper';
import { setIsPastPrescriberFlow } from 'state/physician/physician.reducer';
import { setIsPastPharmacyFlow } from 'state/pharmacy/pharmacy.reducer';

export const useAddTransferPrescription = () => {
    const currentStepState = useSelector(addTransferPrescriptionStepSelector);
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const dispatch = useDispatch();

    const setPrescriptionFlowType = (flowType: FlowTypePayload) => {
        dispatch(setFlowType(flowType));
    };
    const resetForm = () => {
        const submittedPrescribers = storageHelper.session.getSubmittedPrescribers();
        dispatch(setIsPastPrescriberFlow(submittedPrescribers?.length > 0 ? true : false));
        const getSubmittedPharmacies = storageHelper.session.getSubmittedPharmacies();
        dispatch(setIsPastPharmacyFlow(getSubmittedPharmacies?.length > 0 ? true : false));
        dispatch(clearAddTransferObject());
    };
    const incrementStep = () => {
        dispatch(setStep({ currentStep: currentStepState + 1 }));
    };
    const goToStep = (step: number) => {
        dispatch(setStep({ currentStep: step }));
    };
    const decrementStep = () => {
        dispatch(setStep({ currentStep: currentStepState - 1 }));
    };
    const checkPaymentExists = () => {
        if (allPaymentData === undefined || allPaymentData.length === 0) {
            dispatch(setShowPaymentStep({ showPaymentStep: true }));
            return 1;
        } else {
            dispatch(setShowPaymentStep({ showPaymentStep: false }));
            return 0;
        }
    };
    const checkShippingExists = () => {
        if (profileAddresses === undefined || profileAddresses.length === 0) {
            dispatch(setShowAddressStep({ showAddressStep: true }));
            return 1;
        } else {
            dispatch(setShowAddressStep({ showAddressStep: false }));
            return 0;
        }
    };
    const setTotalNumberOfSteps = (totalSteps: number) => {
        return (paymentStep: any) => {
            return (addressStep: any) => {
                dispatch(setTotalStep({ totalSteps: totalSteps + paymentStep + addressStep }));
            };
        };
    };

    return {
        resetForm,
        incrementStep,
        goToStep,
        decrementStep,
        setPrescriptionFlowType,
        checkPaymentExists,
        checkShippingExists,
        setTotalNumberOfSteps
    };
};
