import { createSelector } from '@reduxjs/toolkit';
import { ImageState } from './upload-photo.reducers';
import { RootState } from 'state/store';

export const uploadPhotoSelector = (state: RootState) => state.uploadPhotoReducer;

export const uploadPhotoImgSrcSelector = createSelector(uploadPhotoSelector, (state: ImageState) => state.imageSrc);

export const photoComponentDisabledSelector = createSelector(
    uploadPhotoSelector,
    (state: ImageState) => state.isPhotoComponentDisabled || false
);
