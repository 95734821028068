import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';

import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import { PrescriptionLayoutFormWrapperProps } from 'components/prescription-layout-form-wrapper/prescription-layout-form-wrapper.props';
import UploadPhoto from 'components/upload-photo';

import {
    setShowAddressStep,
    setShowPaymentStep,
    setStep
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import {
    addTransferPrescriptionIsPhotoUploadedSelector,
    addTransferPrescriptionStepSelector
} from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { familyProfileGetDependentsRoutine } from 'state/family-profile/family-profile.routines';
import { setLastPharmacySearchFormValues } from 'state/pharmacy/pharmacy.reducer';
import { setImageSrc, setIsPhotoComponentEnabled } from 'state/upload-photo/upload-photo.reducers';

import { useGlobalLink } from 'hooks/useGlobalLink';

import './prescription-layout-form-wrapper.style.scss';

const PrescriptionLayoutFormWrapper = ({
    children,
    className,
    eyebrowText,
    flowType,
    steps
}: PrescriptionLayoutFormWrapperProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const globalLink = useGlobalLink();
    const currentBackgroundImage = steps[0].backgroundImage;
    const currentStepState = useSelector(addTransferPrescriptionStepSelector);
    const isPhotoUploaded = useSelector(addTransferPrescriptionIsPhotoUploadedSelector);

    useEffect(() => {
        dispatch(familyProfileGetDependentsRoutine.trigger());
        dispatch(setShowPaymentStep({ showPaymentStep: false }));
        dispatch(setShowAddressStep({ showAddressStep: false }));
        dispatch(setStep({ currentStep: 0 }));

        // clear out any previous pharmacy search info
        dispatch(setLastPharmacySearchFormValues(undefined));
        dispatch(setIsPhotoComponentEnabled(false));
    }, [dispatch]);

    useEffect(() => {
        const flowName = 'Transfer Rx';
        if (steps[currentStepState]) {
            // set global link properties to track form abandonment
            globalLink.setFlowName(flowName);
            globalLink.setStepName(steps[currentStepState].dataGAFormStepName ?? '');
            globalLink.setFormName(steps[currentStepState].dataGAFormName ?? '');
        }
    }, [currentStepState, globalLink, steps, t]);

    const resetImageUpload = () => {
        dispatch(setImageSrc(''));
    };

    const classes = classNames('prescription-layout-form-wrapper', className);

    return (
        <WorkflowLayout backgroundImage={currentBackgroundImage}>
            <div className={classes}>
                <div
                    className={classNames('prescription-layout-form-wrapper-content', {
                        'back-button-wrapper': isPhotoUploaded
                    })}
                >
                    <div className="prescription-layout-form-wrapper-content-header">
                        {isPhotoUploaded && (
                            <div className="back-button">
                                <Button
                                    chevron="left"
                                    label={t('prescriptionLayout.back')}
                                    onClick={resetImageUpload}
                                    type="button"
                                    variant="text-blue"
                                />
                            </div>
                        )}
                        {eyebrowText && <div className="eyebrow-text">{eyebrowText}</div>}
                        {steps.map((step, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {currentStepState === index && <h1 className="h2">{step.title}</h1>}
                                </React.Fragment>
                            );
                        })}
                        <div className="spacer" />
                    </div>
                    <UploadPhoto />
                    {steps.map((step, index) => (
                        <React.Fragment key={index}>
                            {currentStepState === index && <div>{step.step}</div>}
                        </React.Fragment>
                    ))}
                </div>
                {children}
            </div>
        </WorkflowLayout>
    );
};

export default PrescriptionLayoutFormWrapper;
